import React, {useState} from 'react';
import {Link} from 'react-router-dom';

function Navbar() {
   const [click, setClick] = useState(false);

   const handleClick = () => setClick(!click);

    return (
    <>
    <div className="main-wrapper">
        <header className="edu-header header-transparent header-sticky">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-xl-2 col-md-6 col-6">
                        <div className="logo">
                            <Link to="/"><img className="logo-light" src="assets/images/logo/logo.png" alt="Site Logo"/></Link>
                        </div>
                    </div>
                    <div className="col-lg-10 d-none d-xl-block">
                        <nav className="mainmenu-nav d-none d-lg-block">
                            <ul className="mainmenu">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About Acalenses</Link></li>
                                <li><Link to="/contact">Request Demo</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-lg-6 col-xl-1 col-md-6 col-6">
                        <div className="header-right d-flex justify-content-end">
                            <div className="header-quote">                               
                                <div className="hamberger quote-icon d-block d-xl-none">
                                    <button className="hamberger-button" onClick={handleClick}><i className={click ? 'ri-close-line' : 'ri-menu-line'} /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className={click ? 'popup-mobile-menu active' : 'popup-mobile-menu'}>
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                        <a href="index.html">
                            <img src="assets/images/logo/logo.png" alt="Site Logo"/>
                        </a>
                    </div>
                    <div className="close-menu">
                        <button className="close-button" onClick={handleClick}>
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    <li><a href="#">Home</a></li>
                    <li><Link to="/about">About Acalenses</Link></li>
                    <li><Link to="/contact">Request Demo</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </div>
        </div> 
      </div> 
      </> 
    )
}

export default Navbar