import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
        <footer className="eduvibe-footer-one edu-footer footer-style-default">
            <div className="footer-top">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="edu-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img className="logo-light" src="assets/images/logo/logo-white.png" width="70%" alt="Site Logo"/>
                                    </Link>
                                </div>
                                <p className="description">Granting you speed and efficiency in your school operations.</p>
                                <ul className="social-share">
                                    <li><a href="#"><i className="icon-Fb"></i></a></li>
                                    <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                    <li><a href="#"><i className="icon-Twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="edu-footer-widget explore-widget">
                                <h5 className="widget-title">Explore</h5>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><Link to="/about"><i className="icon-Double-arrow"></i>About Us</Link></li>
                                        <li><a href="#"><i className="icon-Double-arrow"></i>Privacy Policy</a></li>
                                        <li><a href="#"><i className="icon-Double-arrow"></i> Terms and Condition</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="edu-footer-widget quick-link-widget">
                                <h5 className="widget-title">Useful Links</h5>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><Link to="/contact"><i className="icon-Double-arrow"></i>Contact Us</Link></li>
                                        <li><a href="#"><i className="icon-Double-arrow"></i>Pricing Plan</a></li>
                                        <li><a href="#"><i className="icon-Double-arrow"></i>FAQ</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="edu-footer-widget">
                                <h5 className="widget-title">Contact Info</h5>
                                <div className="inner">
                                    <div className="widget-information">
                                        <ul className="information-list">
                                            <li><i className="icon-phone-fill"></i><a href="tel:+234 902 403 9792">+234 902 403 9792</a></li>
                                            <li><i className="icon-phone-fill"></i><a href="tel:+2348038315886">+234 803 831 5886</a></li>
                                            <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:info@acalensesplus.com">info@acalenses.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="assets/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="assets/images/shapes/shape-35.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area copyright-default">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <p>Copyright  <a href="#">AcaLensesPlus</a> A Product of  <a taregt="_blank" href="https://hardensoft.com.ng/">Hardensoft Limited</a>. All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    <div className="rn-progress-parent">
        <svg className="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
    </>
  );
}

export default Footer;