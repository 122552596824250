import React from "react";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <div className="main-wrapper">
        <div className="slider-area banner-style-4 bg-image">
            <div className="wrapper d-flex align-items-center home-four-banner-wrapper">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="inner mt_md--10 mt_sm--10">
                                <div className="content text-start">
                                    <span className="pre-title">Welcome to Acalenses Plus</span>
                                    <h1 className="title" data-sal-delay="250">A World Class School Digitalization</h1>
                                    <p className="description" data-sal-delay="350">Granting you speed and efficiency in your school operations.</p>
                                    <div className="read-more-btn" data-sal-delay="450">
                                        <Link className="edu-btn" to="/contact">Request Demo <i className="icon-arrow-right-line-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="banner-image">
                                <div className="thumbnail"><img src="assets/images/banner/banner-04/banner-03.png" alt="Banner Images" /></div>
                                <div className="round-images"><img src="assets/images/banner/banner-04/hero-04-round.png" alt="Shape Images" /></div>
                                <div className="learner-badge">
                                    <div className="badge-inner"><span className="viewer">5K</span><span>Enrolled</span><span>Users</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1"><img src="assets/images/shapes/shape-05-03.png" alt="Shape Thumb" /></div>
                        <div className="shape-image shape-image-2"><img src="assets/images/shapes/shape-05-04.png" alt="Shape Thumb" /></div>
                        <div className="shape-image shape-image-3"><img src="assets/images/shapes/shape-19-04.png" alt="Shape Thumb" /></div>
                        <div className="shape-image shape-image-4"><img src="assets/images/shapes/shape-01-04.png" alt="Shape Thumb" /></div>
                        <div className="shape-image shape-image-5"><img src="assets/images/shapes/shape-01-03.png" alt="Shape Thumb" /></div>
                        <div className="shape-image shape-image-6"><img src="assets/images/shapes/shape-06.png" alt="Shape Thumb" /></div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-left eduvibe-home-4-hero-svg" data-sal>
                        <svg width="223" height="272" viewBox="0 0 223 272" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 11.0378C15 5.37117 49.6 -3.46216 76 6.53784C109 19.0378 106.5 62.0378 81.5 91.0378C56.5 120.038 43 138.038 49 166.538C55 195.038 94 189.038 122.5 152.538C151 116.038 191.5 107.038 211.5 138.538C231.5 170.038 216.5 199.038 196 209.538C175.5 220.038 144 198.538 162.5 176.538C181 154.538 213 183.538 202 213.038C191 242.538 158.5 255.038 152 270.538" stroke="#BCBDEC" stroke-width="2" />
                        </svg>
                    </div>

                    <div className="shape-image shape-image-right eduvibe-home-4-hero-svg" data-sal>
                        <svg width="233" height="348" viewBox="0 0 233 348" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5304 336C8.69704 330.667 -0.269631 314.6 2.53037 293C6.03037 266 33.0312 252 51.0312 264.5C69.0312 277 74.0312 302 81.0312 316C88.0312 330 112.031 351.5 141.531 345C171.031 338.5 176.031 324.5 166.031 300.5C156.031 276.5 118.031 273.5 97.5312 266.5C77.0312 259.5 51.5312 248 56.5312 219C61.5312 190 90.0312 195 102.031 209C114.031 223 148.531 232.5 161.531 215C174.531 197.5 163.031 180 150.531 181C138.031 182 129.531 193.5 135.031 205.5C140.531 217.5 170.531 229 191.031 202.5C211.531 176 195.031 152.5 180.031 134.5C165.031 116.5 146.031 90 160.531 50.5C175.031 11 213.031 2.5 232.031 2" stroke="#FFC9C9" stroke-width="2" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default HeroSection;
