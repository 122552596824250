import React from 'react';
import Footer from '../footer';
import Breadcrumb from '../Breadcrumb';

function Contact() {
  return (
    <>
       <Breadcrumb  title="Contact"/>
        
        <div class="edu-contact-us-area eduvibe-contact-us edu-section-gap bg-color-white">
            <div class="container eduvibe-animated-shape">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title text-center">
                            <span class="pre-title">Need Help?</span>
                            <h3 class="title">Get In Touch With us</h3>
                        </div>
                    </div>
                </div>
                <div class="row g-5 mt--20">
                    <div class="col-lg-6">
                        <div class="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                            <div class="row g-5">
                             <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="contact-address-card-1 phone">
                                        <div class="inner">
                                            <div class="icon">
                                                <i class="icon-Headphone"></i>
                                            </div>
                                            <div class="content">
                                                <h6 class="title">Call Us On</h6>
                                                <p><a href="tel:+234 902 403 9792">+234 902 403 9792</a></p>
                                                <p><a href="tel:+234 803 831 5886">+234 803 831 5886</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="contact-address-card-1 email">
                                        <div class="inner">
                                            <div class="icon">
                                                <i class="icon-mail-open-line"></i>
                                            </div>
                                            <div class="content">
                                                <h6 class="title">Email Us</h6>
                                                <p><a href="mailto:info@acalenses.com" target="_blank">acalenses.com</a></p>
                                                <p><a href="mailto:info@acalenses.com" target="_blank">acalenses.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <form class="rnt-contact-form rwt-dynamic-form row" id="contact-form" method="POST" action="https://eduvibe.html.devsvibe.com/mail.php">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input name="contact-name" id="contact-name" type="text" class="form-control form-control-lg" placeholder="School Name*"/>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="email" class="form-control form-control-lg" id="contact-email" name="contact-email" placeholder="Email*"/>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-lg" name="contact-phone" id="contact-phone" placeholder="Phone"/>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <textarea class="form-control" name="contact-message" id="contact-message" placeholder="Your Message"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <button class="rn-btn edu-btn w-100" name="submit" type="submit">
                                    <span>Submit Now</span><i class="icon-arrow-right-line-right"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

              
            </div>
         </div>

       <Footer/>
    </>
  )
}

export default Contact