import React from  'react';
import Navbar from './components/Navbar';
import Home from "./components/pages/home";
import Contact from "./components/pages/Contact";
import Services from "./components/pages/services";
import About from "./components/pages/About";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
