import React from "react";
import HeroSection from "../HeroSection";
import Footer from "../footer";
import Slider from "react-slick";
import { useEffect } from "react";

function Home() {      
      useEffect(() => {
        document.title = "Index | AcaclensesPlus ";
      }, []);
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <button className="slide-arrow prev-arrow" onClick={onClick}>
            <i className="icon-arrow-left-line"></i>
          </button>
        );
      }
       function SampleNextArrow(props) {
        const { className, style, onClick } = props;
         return (
           <button className="slide-arrow next-arrow" onClick={onClick}><i className="icon-arrow-right-line-right"></i></button>
         );
       }
      var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 577,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
  return (
    <>
      <HeroSection />
      <div className="eduvibe-home-five-cats edu-about-area about-style-5 edu-section-gapTop bg-color-white">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <div className="section-title" data-sal-delay="150">
                <h3 className="title">Why AcalensesPlus</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mt--60 mb_dec--20 slick-activation-wrapper service-activation-item5 edu-slick-arrow-top">
              <Slider {...settings}>
                <div className="single-slick-card">
                  <div className="service-card service-card-8 shape-bg-1">
                    <div className="inner">
                      <div className="icon">
                        <a href="#">
                          <img src="assets/images/icons/pc.png" alt="Icons"/>
                        </a>
                      </div>
                      <div className="content">
                        <h6 className="title">
                          <a href="#">Easy Usage</a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="single-slick-card">
                  <div className="service-card service-card-8 shape-bg-2">
                    <div className="inner">
                      <div className="icon">
                        <a href="#">
                          <img src="assets/images/icons/www.png" alt="Icons" />
                        </a>
                      </div>
                      <div className="content">
                        <h6 className="title">
                          <a href="#">Access from Anywhere</a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="single-slick-card">
                  <div className="service-card service-card-8 shape-bg-2">
                    <div className="inner">
                      <div className="icon">
                        <a href="#">
                          <img src="assets/images/icons/phone.png" alt="Icons" />
                        </a>
                      </div>
                      <div className="content">
                        <h6 className="title">
                          <a href="#">Mobile Friendly</a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="assets/images/shapes/shape-07.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="assets/images/shapes/shape-03-05.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="assets/images/shapes/shape-04-06.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </div>

      <div className=" eduvibe-home-five-about edu-about-area about-style-6 edu-section-gap bg-color-white">
        <div className="container eduvibe-animated-shape">
          <div className="row row--50">
            <div className="col-xl-5 col-lg-6">
              <div className="about-image-gallery">
                <img className="image-1 w-80 w-100" src="assets/images/about/lady.png" alt="About Images" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 offset-xl-1">
              <div className="inner mt_md--40 mt_sm--40">
                <div className="section-title">
                  <span className="pre-title">About Us</span>
                  <h3 className="title">Get to know us better</h3>
                </div>
                <p className="description mt--40 mt_md--20 mt_sm--20" >
                  AcaLensesPlus is an online user friendly, cost effective,
                  multi-platform solution from our company (HARDENSOFT), that
                  aims at providing optimum value for school owners, pupils,
                  parents, and teachers, while also generating extra income for
                  the school.
                </p>
                <p>
                 As a product of HardenSoft International Limited, HardenSoft International Limited is an incorporated cutting edge Information Technology and Multimedia company, founded over 33Years ago. It has left its indelible mark on the sands of time by offering world-class solutions to clients across diverse sectors and nations.
                </p>

                <div className="read-more-btn mt--40">
                  <a className="edu-btn" href="about-us-1.html">
                    Contact Us<i className="icon-arrow-right-line-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="assets/images/shapes/shape-11-05.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="assets/images/shapes/shape-08-01.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="assets/images/shapes/shape-30.png" alt="Shape Thumb" />
            </div>
            <div className="shape shape-1">
              <span className="shape-dot"></span>
            </div>
          </div>
        </div>
      </div>

      <div className="sercice-area eduvibe-service-four edu-section-gap bg-color-white">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="pre-title">Some Major </span>
                <h3 className="title">
                  Features of <span className="colortext">AcaLensesPlus</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--25">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="service-card service-card-4">
                <div className="inner">
                  <div className="icon">
                    <i className="icon-Hand---Book"></i>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Digital Access System</a>
                    </h6>
                    <p className="description">
                      The system comes with the integration of digital access
                      cards.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="service-card service-card-4">
                <div className="inner">
                  <div className="icon">
                    <i className="icon-research"></i>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Academic Records Management</a>
                    </h6>
                    <p className="description">
                      This system enhances the periodic upload of academic
                      performance for each pupil on the system
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="service-card service-card-4">
                <div className="inner">
                  <div className="icon">
                    <i className="icon-compass"></i>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Financial Records Management</a>
                    </h6>
                    <p className="description">
                      Keeping detailed track of all income and expenditure is
                      very vital
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="service-card service-card-4">
                <div className="inner">
                  <div className="icon">
                    <i className="icon-zoom-in-line"></i>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Child Personality style tracker</a>
                    </h6>
                    <p className="description">
                      AcaLenses + features a child personality performance
                      tracker.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="service-card service-card-4">
                <div className="inner">
                  <div className="icon">
                    <i className="icon-Star"></i>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Quality Asurance</a>
                    </h6>
                    <p className="description">
                      The cycle of Communication is incomplete when feedback has not been received.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="service-card service-card-4">
                <div className="inner">
                  <div className="icon">
                    <i className="icon-Browser"></i>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Live/Archived Online Classes</a>
                    </h6>
                    <p className="description">
                      Acalenses plus integrates the ability for lectures to hold from any Location.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="service-card service-card-4">
                <div className="inner">
                  <div className="icon">
                    <i className="icon-book-3-line"></i>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">Online Information Portal</a>
                    </h6>
                    <p className="description">
                      We provide information portal online
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="service-card service-card-4">
                <div className="inner">
                  <div className="icon">
                    <i className="icon-calculator"></i>
                  </div>
                  <div className="content">
                    <h6 className="title">
                      <a href="#">CBT Functions</a>
                    </h6>
                    <p className="description">
                      With AcaLenses plus, all forms of tests and examinations can be conducted online.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Home;
